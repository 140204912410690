import {
	Timesheet,
	CopyTimeSheetRequestArguments,
	PrintTimesheetRequestArguments,
	AddTimesheetLineItemsArguments,
	CurrentTimesheetData,
	ReleaseTimeSheetArguments,
} from '../../types/api/timesheets';
import api from '../../middleware/api';
import { WeekRangeData, setWeekRangeData } from '../time-actions';

/*
 * GET_TIME_SHEET
 */

export const GET_TIME_SHEET_STARTED = 'GET_TIME_SHEET_STARTED';
export function getTimesheetStarted ( startedAt: number ) {
	return {
		type: GET_TIME_SHEET_STARTED,
		startedAt,
	};
}

export const GET_TIME_SHEET_SUCCEEDED = 'GET_TIME_SHEET_SUCCEEDED';
export function getTimesheetSucceeded (
	timesheets: Array<Timesheet>,
	startedAt?: number
) {
	return {
		type: GET_TIME_SHEET_SUCCEEDED,
		timesheets,
		startedAt,
	};
}

export const GET_TIME_SHEET_FAILED = 'GET_TIME_SHEET_FAILED';
export function getTimesheetFailed ( error: any, startedAt: number ) {
	return {
		type: GET_TIME_SHEET_FAILED,
		error: 'Failed to load timesheet data',
		startedAt,
	};
}

export function getTimesheet ( from: Date, to: Date, started?: number ) {
	return function ( dispatch: Function ) {
		const startedAt = started ? started : new Date().getTime();
		dispatch( getTimesheetStarted( startedAt ) );

		api
			.getWeekTimesheet( from )
			.then( ( timesheets: Array<Timesheet> ) =>
				dispatch( getTimesheetSucceeded( timesheets, startedAt ) )
			)
			.catch( ( err: any ) => dispatch( getTimesheetFailed( err, startedAt ) ) );
	};
}

/*
 * COPY_TIMESHEET
 */

export const COPY_TIMESHEET_STARTED = 'COPY_TIMESHEET_STARTED';
export function copyTimesheetStarted ( startedAt: number ) {
	return {
		type: COPY_TIMESHEET_STARTED,
		startedAt,
	};
}

export const COPY_TIMESHEET_SUCCEEDED = 'COPY_TIMESHEET_SUCCEEDED';
export function copyTimesheetSucceeded () {
	return {
		type: COPY_TIMESHEET_SUCCEEDED,
	};
}

export const COPY_TIMESHEET_FAILED = 'COPY_TIMESHEET_FAILED';
export function copyTimesheetFailed ( startedAt: number ) {
	return {
		type: COPY_TIMESHEET_FAILED,
		startedAt,
	};
}

export function copyTimesheet (
	requestData: CopyTimeSheetRequestArguments,
	weekRange: WeekRangeData
) {
	const startedAt = new Date().getTime();
	return function ( dispatch: Function ) {
		dispatch( copyTimesheetStarted( startedAt ) );
		api
			.copyTimesheet( requestData )
			.then( () => {
				dispatch( copyTimesheetSucceeded() );
				dispatch( getTimesheet( weekRange.from, weekRange.to, startedAt ) );
			} )
			.catch( () => copyTimesheetFailed( startedAt ) );
	};
}

/*
 * PRINT_TIMESHEET
 */

export const PRINT_TIMESHEET_STARTED = 'PRINT_TIMESHEET_STARTED';
export function printTimesheetStarted () {
	return {
		type: PRINT_TIMESHEET_STARTED,
	};
}

export const PRINT_TIMESHEET_SUCCEEDED = 'PRINT_TIMESHEET_SUCCEEDED';
export function printTimesheetSucceeded ( message: string ) {
	return {
		type: PRINT_TIMESHEET_SUCCEEDED,
		message,
	};
}

export const PRINT_TIMESHEET_FAILED = 'PRINT_TIMESHEET_FAILED';
export function printTimesheetFailed () {
	return {
		type: PRINT_TIMESHEET_FAILED,
	};
}

export function printTimesheet ( requestData: PrintTimesheetRequestArguments ) {
	return function ( dispatch: Function ) {
		dispatch( printTimesheetStarted() );
		api
			.printTimesheet( requestData )
			.then( ( message: string ) => {
				dispatch( printTimesheetSucceeded( message ) );
			} )
			.catch( printTimesheetFailed );
	};
}

/*
 * ADD_TIMESHEET
 */

export const ADD_TIMESHEET_STARTED = 'ADD_TIMESHEET_STARTED';
export function addTimesheetStarted ( startedAt: number ) {
	return {
		type: ADD_TIMESHEET_STARTED,
		startedAt,
	};
}

export const ADD_TIMESHEET_SUCCEEDED = 'ADD_TIMESHEET_SUCCEEDED';
export function addTimesheetSucceeded (
	timesheets: Array<Timesheet>,
	startedAt: number
) {
	return {
		type: ADD_TIMESHEET_SUCCEEDED,
		timesheets,
		startedAt,
	};
}

export const ADD_TIMESHEET_FAILED = 'ADD_TIMESHEET_FAILED';
export function addTimesheetFailed ( error: any, startedAt: number ) {
	return {
		type: ADD_TIMESHEET_FAILED,
		error: 'Failed to add timesheet',
		startedAt,
	};
}

export function addTimesheet ( {
	year,
	week,
	employeeCode,
	addLineItemRequestRecords,
}: AddTimesheetLineItemsArguments ) {
	return function ( dispatch: Function ) {
		const startedAt = new Date().getTime();
		dispatch( addTimesheetStarted( startedAt ) );

		api
			.addTimesheetLineItems( {
				year,
				week,
				employeeCode,
				addLineItemRequestRecords,
			} )
			.then( ( result: { data: Array<Timesheet> } ) => {
				dispatch( addTimesheetSucceeded( result.data, startedAt ) );
			} )
			.catch( ( err: any ) => {
				dispatch( addTimesheetFailed( err, startedAt ) );
			} );
	};
}

/*
 * GET_CURRENT_TIME_SHEET
 */

export const GET_CURRENT_TIME_SHEET_STARTED = 'GET_CURRENT_TIME_SHEET_STARTED';
export function getCurrentTimesheetStarted ( startedAt: number ) {
	return {
		type: GET_CURRENT_TIME_SHEET_STARTED,
		startedAt,
	};
}

export const GET_CURRENT_TIME_SHEET_SUCCEEDED =
	'GET_CURRENT_TIME_SHEET_SUCCEEDED';
export function getCurrentTimesheetSucceeded (
	currentTimesheets: Array<CurrentTimesheetData>,
	startedAt: number
) {
	return {
		type: GET_CURRENT_TIME_SHEET_SUCCEEDED,
		currentTimesheets,
		timesheets: currentTimesheets,
		startedAt,
	};
}

export const GET_CURRENT_TIME_SHEET_FAILED = 'GET_CURRENT_TIME_SHEET_FAILED';
export function getCurrentTimesheetFailed ( error: any, startedAt: number ) {
	return {
		type: GET_CURRENT_TIME_SHEET_FAILED,
		error: 'Failed to load current timesheet data!',
		startedAt,
	};
}

export function getCurrentTimesheet () {
	const startedAt = new Date().getTime();
	return function ( dispatch: Function ) {
		dispatch( getCurrentTimesheetStarted( startedAt ) );

		api
			.getCurrentTimesheet()
			.then( ( currentTimesheets: Array<CurrentTimesheetData> ) => {
				dispatch( getCurrentTimesheetSucceeded( currentTimesheets, startedAt ) );
			} )
			.catch( ( err: any ) => dispatch( getCurrentTimesheetFailed( err, startedAt ) ) );
	};
}

export function getNextWeekRangeAfterGetCurrentTimesheet (
	nextWeekRange?: WeekRangeData
) {
	const startedAt = new Date().getTime();
	return function ( dispatch: Function ) {
		dispatch( getCurrentTimesheetStarted( startedAt ) );
		api
			.getCurrentTimesheet()
			.then( ( currentTimesheets: Array<CurrentTimesheetData> ) => {
				dispatch( getCurrentTimesheetSucceeded( currentTimesheets, startedAt ) );
				if ( nextWeekRange ) {
					dispatch( setWeekRangeData( nextWeekRange ) );
				}
			} )
			.catch( ( err: any ) => dispatch( getCurrentTimesheetFailed( err, startedAt ) ) );
	};
}

// DELETE_TIMESHEET
export const DELETE_TIME_SHEET_LINE_ITEM_STARTED =
	'DELETE_TIME_SHEET_LINE_ITEM_STARTED';
export function deleteTimesheetLineItemStarted ( startedAt: number ) {
	return {
		type: DELETE_TIME_SHEET_LINE_ITEM_STARTED,
		startedAt,
	};
}

export const DELETE_TIME_SHEET_LINE_ITEM_SUCCEEDED =
	'DELETE_TIME_SHEET_LINE_ITEM_SUCCEEDED';
export function deleteTimesheetLineItemSucceeded () {
	return {
		type: DELETE_TIME_SHEET_LINE_ITEM_SUCCEEDED,
	};
}

export const DELETE_TIME_SHEET_LINE_ITEM_FAILED =
	'DELETE_TIME_SHEET_LINE_ITEM_FAILED';
export function deleteTimesheetLineItemFailed ( error: any, startedAt: number ) {
	return {
		type: DELETE_TIME_SHEET_LINE_ITEM_FAILED,
		error: 'Failed to delete timesheet',
		startedAt,
	};
}

export function deleteTimesheetLineItem (
	weekRange: WeekRangeData,
	year: number,
	week: number,
	lineItemIds: string[]
) {
	return function ( dispatch: Function ) {
		const startedAt = new Date().getTime();
		dispatch( deleteTimesheetLineItemStarted( startedAt ) );
		api
			.deleteTimesheetLineItems( { year, week, lineItemIds } )
			.then( () => {
				const { from, to } = weekRange;
				dispatch( deleteTimesheetLineItemSucceeded() );
				dispatch( getTimesheet( from, to, startedAt ) );
			} )
			.catch( ( err: any ) =>
				dispatch( deleteTimesheetLineItemFailed( err, startedAt ) )
			);
	};
}

/*
 * RELEASE_TIMESHEET
 */

export const RELEASE_TIMESHEET_STARTED = 'RELEASE_TIMESHEET_STARTED';
export function releaseTimesheetStarted () {
	return {
		type: RELEASE_TIMESHEET_STARTED,
	};
}

export const RELEASE_TIMESHEET_FAILED = 'RELEASE_TIMESHEET_FAILED';
export function releaseTimesheetFailed ( error: any ) {
	return {
		type: RELEASE_TIMESHEET_FAILED,
		error,
	};
}

export const RELEASE_TIMESHEET_SUCCEEDED = 'RELEASE_TIMESHEET_SUCCEEDED';
export function releaseTimesheetSucceeded () {
	return {
		type: RELEASE_TIMESHEET_SUCCEEDED,
	};
}

export function releaseTimesheet (
	releaseTimesheetsArguments: ReleaseTimeSheetArguments,
	nextWeekRange?: WeekRangeData
) {
	return function ( dispatch: Function ) {
		dispatch( releaseTimesheetStarted() );
		api
			.releaseTimesheet( releaseTimesheetsArguments )
			.then( () => {
				dispatch( releaseTimesheetSucceeded() );
				// We fetch the data for the things that have changed:
				// - current timesheets, because the week that we have to log our time to, has changed
				dispatch( getNextWeekRangeAfterGetCurrentTimesheet( nextWeekRange ) );
			} )
			.catch( ( err: any ) => {
				dispatch( releaseTimesheetFailed( err ) );
			} );
	};
}

export const UNDO_RELEASE_TIMESHEET_STARTED = 'UNDO_RELEASE_TIMESHEET_STARTED';
export function undoReleaseTimesheetStarted () {
	return {
		type: UNDO_RELEASE_TIMESHEET_STARTED,
	};
}

export const UNDO_RELEASE_TIMESHEET_SUCCEEDED =
	'UNDO_RELEASE_TIMESHEET_SUCCEEDED';
export function undoReleaseTimesheetSucceeded () {
	return {
		type: UNDO_RELEASE_TIMESHEET_SUCCEEDED,
	};
}

export const UNDO_RELEASE_TIMESHEET_FAILED = 'UNDO_RELEASE_TIMESHEET_FAILED';
export function undoReleaseTimesheetFailed ( error: any ) {
	return {
		type: UNDO_RELEASE_TIMESHEET_FAILED,
		error,
	};
}

export function undoReleaseTimesheet (
	releaseTimesheetsArguments: ReleaseTimeSheetArguments,
	setNextWeekRange: ( numberOfWeeks?: number ) => void
) {
	return function ( dispatch: Function ) {
		dispatch( undoReleaseTimesheetStarted() );
		api
			.releaseTimesheet( releaseTimesheetsArguments )
			.then( () => {
				dispatch( undoReleaseTimesheetSucceeded() );
				// We fetch the data for the things that have changed:
				// - current timesheets, because the week that we have to log our time to, has changed
				dispatch( getCurrentTimesheet() );
				setNextWeekRange();
			} )
			.catch( ( err: any ) => {
				dispatch( undoReleaseTimesheetFailed( err ) );
			} );
	};
}
