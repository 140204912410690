import React from 'react';
import MaterialChip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles( ( theme ) => ( {
	chip: {
		color: theme.palette.primary.contrastText,
		borderRadius: '4px',
		fontFamily: theme.typography.body2.fontFamily,
		fontWeight: 'bold',
		fontSize: '1.3rem',
		lineHeight: 1.1,
	},
	bigChip: {
		fontSize: '1.6rem',
		fontWeight: 'normal',
	},
	primaryChip: {
		backgroundColor: theme.palette.primary.main,
	},
	secondaryChip: {
		backgroundColor: theme.palette.secondary.main,
	},
	darkChip: {
		backgroundColor: theme.palette.custom.darkBlueGray,
	},
	transparentChip: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		cursor: 'pointer',
	},
	dangerChip: {
		color: theme.palette.secondary.main,
		backgroundColor: theme.palette.background.paper,
		cursor: 'pointer',
	},
} ) );

export interface ChipProps {
	color?:
	| 'primary'
	| 'secondary'
	| 'dark'
	| 'transparent'
	| 'transparent-danger';
	label: string;
	variant?: 'normal' | 'big';
	style?: React.CSSProperties;
}

const Chip: React.FC<ChipProps> = ( { color, label, style, variant } ) => {
	const classes = useStyles();
	let colorClass = classes.darkChip;

	switch ( color ) {
		case 'primary':
			colorClass = classes.primaryChip;
			break;
		case 'secondary':
			colorClass = classes.secondaryChip;
			break;
		case 'transparent':
			colorClass = classes.transparentChip;
			break;
		case 'transparent-danger':
			colorClass = classes.dangerChip;
			break;
	}

	const className = clsx(
		classes.chip,
		colorClass,
		variant ? classes.bigChip : undefined
	);

	return (
		<MaterialChip
			className={ className }
			style={ style ? style : undefined }
			size="small"
			label={ label }
			title={ label }
		/>
	);
};

export default Chip;
